import { ColumnsType } from 'antd/es/table';
import { BroadCast, getFieldName } from 'src/models/broadCast';
import { dateToYMdhm } from 'src/util';

export const columns: ColumnsType<BroadCast> = [
  {
    title: '配信状況',
    dataIndex: getFieldName('statusInJp'),
    key: getFieldName('statusInJp'),
  },
  {
    title: '配信時刻',
    dataIndex: getFieldName('sendAt'),
    key: getFieldName('sendAt'),
    render(v: Date | null) {
      if (v === null) {
        return '-';
      }
      return dateToYMdhm(v);
    },
  },
  {
    title: 'タイトル',
    dataIndex: getFieldName('title'),
    key: getFieldName('title'),
  },
  {
    title: '配信対象',
    dataIndex: getFieldName('sendMCustomerLineIds'),
    key: getFieldName('sendMCustomerLineIds'),
    render(v: string[] | null) {
      if (v === null || v.length === 0) {
        return '全員';
      } else {
        return 'ファイルアップロード';
      }
    },
  },
];
