import useSWR from 'swr';
import { messageRoomCacheKeyGenerator } from './cache';
import { MessageRoom, MessageRoomId } from 'src/models/messageRoom';
import { useMessageRoomRepository } from 'src/repositories/messageRoom/repository';
import { MCustomer } from 'src/models/mCustomer';
import { message } from 'antd';
import { useLoginAccount } from 'src/globalStates/loginAccount';
import { QueryDocumentSnapshot } from 'firebase/firestore';

export type MessageRoomListResponse = { rooms: MessageRoom[], totalCount: number, firstDoc: QueryDocumentSnapshot | undefined, lastDoc: QueryDocumentSnapshot | undefined };
export type MessageRoomListQuery = {
  mCustomerId: string | null;
  isImportant?: boolean;
  firstDoc?: QueryDocumentSnapshot | undefined;
  lastDoc?: QueryDocumentSnapshot | undefined;
};

export const useMessageRoomList = (query: MessageRoomListQuery) => {
  const repository = useMessageRoomRepository();
  const account = useLoginAccount();

  return useSWR<MessageRoomListResponse>(
    messageRoomCacheKeyGenerator.generateListKey({
      query,
      ignore: !account.merchantId,
    }),
    async () => {
      // 総件数を取得
      const totalCount = await repository.getCount(query);
      if (query.mCustomerId) {
        // idがある場合
        const mCusInfo = await repository.getDocFromMCusId(query);
        
        return {
          rooms: mCusInfo.messageRooms,
          totalCount: totalCount,
          firstDoc: mCusInfo.firstDoc,
          lastDoc: mCusInfo.lastDoc
        };
      }
      else if (query.isImportant !== undefined && query.isImportant === true) {
        // 重要度が星で設定している場合
        const mCusInfo = await repository.getDocIsImportant(query);
        return {
          rooms: mCusInfo.messageRooms,
          totalCount: totalCount,
          firstDoc: mCusInfo.firstDoc,
          lastDoc: mCusInfo.lastDoc
        };
      }
      else {
        // それ以外
        const mCusInfo = await repository.getList(query);
        return {
          rooms: mCusInfo.messageRooms,
          totalCount: totalCount,
          firstDoc: mCusInfo.firstDoc,
          lastDoc: mCusInfo.lastDoc
        };
      }
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};

export type MessageRoomGetItemQuery = {
  mRoomId: MessageRoomId | null;
};

export type MessageRoomResponse = { room: MessageRoom | null };

export const useMessageRoom = (query: MessageRoomGetItemQuery) => {
  const repository = useMessageRoomRepository();
  const account = useLoginAccount();

  return useSWR<MessageRoomResponse>(
    messageRoomCacheKeyGenerator.generateItemKey({
      query,
      ignore: !query.mRoomId || !account.merchantId,
    }),
    async () => {
      return {
        room: await repository.getDoc(query),
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};

export type MessageRoomGetItemResponse = {
  mRoom: MessageRoom | null;
  mCustomer: MCustomer | null;
};
