import React, { useState } from 'react';
import {
  Avatar,
  Badge,
  Button,
  Flex,
  List,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import * as icon from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MessageRoom } from 'src/models/messageRoom';
import { dateToMdhmWithLine } from 'src/util';
import { AppColors } from 'src/components/styles/color';
import { useLoginAccount } from 'src/globalStates/loginAccount';
import { CloseOutlined, LeftOutlined, RightOutlined, StarOutlined, StarTwoTone } from '@ant-design/icons';
import { useMessageRoomRepository } from 'src/repositories/messageRoom/repository';
import { FullscreenLoading } from 'src/components/function/loading';

type Props = {
  data: MessageRoom[];
  totalCount: number;
  pageSize: number;
  selectingRoom: MessageRoom | null;
  searchedRoom: boolean;
  onSelect: (room: MessageRoom) => void;
  onClear: () => void;
  handlePageChange: (direction: "prev" | "next") => void;
  pageNum: number;
};

export const DMList: React.FC<Props> = ({
  data,
  totalCount,
  pageSize,
  selectingRoom,
  searchedRoom,
  onSelect,
  onClear,
  pageNum,
  handlePageChange,
}) => {
  const { stores } = useLoginAccount();
  const repository = useMessageRoomRepository();
  const [load, setLoad] = useState(false);

  // 重要(星)ボタン押下イベント
  const toggleImportant = async (mRoom: MessageRoom) => {
    setLoad(true);
    const tmpIsImportant = mRoom.isImportant ?? false;
    const result = await repository.updateIsImportant(mRoom.id ?? '', !tmpIsImportant);
    if (result) {
      mRoom.isImportant = !tmpIsImportant;
      setLoad(false);
    }
  };

  return (
    <FullscreenLoading isLoading={load}>
      <Flex
        style={{
          width: '23%',
          background: 'white',
          borderRadius: 3,
          alignItems: 'center',
        }}
        vertical
      >
        <div hidden={!searchedRoom}>
          <Button
            style={{ margin: '15px 0 15px 0' }}
            icon={<CloseOutlined />}
            onClick={onClear}
          >{`検索結果 ${data.length}件`}</Button>
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'auto',
          }}
        >
          <InfiniteScroll
            dataLength={data.length}
            next={() => { }}
            hasMore={false}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={data}
              renderItem={(item) => (
                <List.Item
                  key={item.documentStatus}
                  style={{
                    padding: '4%',
                    backgroundColor:
                      selectingRoom?.id === item.id ? '#D9D9D9' : AppColors.white,
                  }}
                  onClick={() => onSelect(item)}
                >
                  <List.Item.Meta
                    avatar={<Avatar size={45} icon={<icon.UserOutlined />} />}
                    title={
                      <Flex justify="space-between" align="center">
                        <Typography.Text style={{ width: '50%' }}>
                          {item.mCusName}
                        </Typography.Text>
                        <Button
                          type="text"
                          icon={item.isImportant ?? false ? <StarTwoTone twoToneColor={"#ed9a00"} /> : <StarOutlined />}
                          onClick={() => toggleImportant(item)}
                        />
                        <Typography.Text
                          style={{
                            fontSize: '9px',
                            marginRight: '2%',
                          }}
                        >
                          {item.lastMessageAt === null
                            ? ''
                            : dateToMdhmWithLine(item.lastMessageAt)}
                        </Typography.Text>
                        {item.unreadMessages === 0 ? (
                          <></>
                        ) : (
                          <Space
                            style={{
                              maxWidth: '20%',
                              justifySelf: 'flex-end',
                            }}
                          >
                            <Badge
                              className="site-badge-count-109"
                              count={
                                item.unreadMessages > 99
                                  ? '99+'
                                  : item.unreadMessages
                              }
                              style={{
                                backgroundColor: AppColors.lightBlue,
                              }}
                            />
                          </Space>
                        )}
                      </Flex>
                    }
                    description={
                      <Flex justify="space-between" style={{ paddingTop: '5px' }}>
                        <Typography.Text ellipsis type="secondary">
                          {item.lastMessage}
                        </Typography.Text>
                        <Badge
                          count={
                            stores.find(
                              (_store) => _store.storeId === item.storeId
                            )?.storeName
                          }
                          color={AppColors.lightGray}
                          style={{ color: AppColors.lightBlue }}
                        />
                      </Flex>
                    }
                  />
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', marginBottom: '5px' }}>
          <Button
            onClick={() => handlePageChange('prev')}
            icon={<LeftOutlined style={{fontSize: "12px"}} />}
            style={{ marginRight: '90px' }}
            disabled={pageNum === 1} />
          <Button
            onClick={() => handlePageChange('next')}
            icon={<RightOutlined style={{fontSize: "12px"}} />}
            style={{ marginLeft: '90px' }}
            disabled={totalCount / pageSize <= pageNum}/>
        </div>
      </Flex>
    </FullscreenLoading>
  );
};
