import React, { useEffect, useRef, useState } from 'react';
import { Flex } from 'antd';
import { SearchBoxWithDropdownBox } from 'src/components/ui';
import { MCustomerDetail } from 'src/components/model/mCustomer/detail';
import { DMList } from 'src/components/model/directMessage/list';
import { Chat } from 'src/components/model/directMessage/chat';
import {
  useMessageRoom,
  useMessageRoomList,
} from 'src/usecases/messageRoom/reader';
import { MessageRoom } from 'src/models/messageRoom';
import { toMCustomerId } from 'src/models/mCustomer';
import { useLocation, useNavigate } from 'react-router-dom';
import RoutePaths from 'src/root/path';
import { getRoomId } from 'src/root/path.function';
import { useMCustomerItem } from 'src/usecases/mCustomer/reader';
import { QueryDocumentSnapshot } from 'firebase/firestore';

// 1ページに表示するデータ数
export const PAGE_SIZE = 50;

export const Message: React.FC = () => {
  const location = useLocation();

  const selectRoomId = getRoomId(location.pathname);

  const { data: room } = useMessageRoom({ mRoomId: selectRoomId });

  const [selectRoom, setSelectRoom] = useState<MessageRoom | null>(null);

  // ドロップダウンの選択肢の文字列
  const FILTER_ALL_STR = 'all';
  const FILTER_IMPORTANT_STR = 'isImportant';
  const FILTER_NOT_IMPORTANT_STR = 'isNotImportant';
  // ドロップダウンの選択肢
  const options = [
    {value: FILTER_ALL_STR, label: "全て表示"},
    {value: FILTER_IMPORTANT_STR, label: "星マークのみ表示"},
    {value: FILTER_NOT_IMPORTANT_STR, label: "星マーク以外を表示"}
  ];

  useEffect(() => {
    if (room?.room && !selectRoom) {
      setSelectRoom(room.room);
    }
    return () => {};
  }, [room, selectRoom]);

  const searchId = new URLSearchParams(location.search).get('id');

  const [selectFilter, setSelectFilter] = useState<string>(FILTER_ALL_STR);
  // 現在選択しているページ
  const [currentPage, setCurrentPage] = useState(1);
  // ページの最初のドキュメント情報
  const firstDoc = useRef<QueryDocumentSnapshot | undefined>(undefined);
  // ページの最後のドキュメント情報
  const lastDoc = useRef<QueryDocumentSnapshot | undefined>(undefined);

  // ぺージングの値をリセットする関数
  const resetPagingStatus = () => {
    setCurrentPage(1);
    firstDoc.current = undefined;
    lastDoc.current = undefined;
  }

  // useMessageRoomListの引数を取得する関数
  const getArgsUseMessageRoomList = () => {
    // フィルターの状態を取得
    let filterStatus: boolean | undefined = undefined;
    if (selectFilter === FILTER_IMPORTANT_STR) {
      // 重要データのみを表示する場合
      filterStatus = true;
    } 
    else if (selectFilter === FILTER_NOT_IMPORTANT_STR) {
      // 重要データ以外を表示する場合
      filterStatus = false;
    }
    // 引数を返す
    return { 
      mCustomerId: searchId, 
      isImportant: filterStatus,
      firstDoc: firstDoc.current,
      lastDoc: lastDoc.current,
    };
  }
  const { data } = useMessageRoomList(getArgsUseMessageRoomList());

  const { data: selectRoomMCus } = useMCustomerItem({
    mCustomerId: selectRoom?.mCusId
      ? toMCustomerId(selectRoom!.mCusId)
      : undefined,
  });
  

  const navigate = useNavigate();

  const onClick = React.useCallback(
    (room: MessageRoom) => {
      setSelectRoom(room);
      if (!searchId) {
        navigate('/' + RoutePaths.directMessage.path + room.id, {
          replace: true,
        });
      }
    },
    [navigate, searchId]
  );

  const onSearch = React.useCallback(
    (_input: string) => {
      // ページングの値をリセット
      resetPagingStatus();

      const _query = _input.replace(/\s/g, '');
      if (_query === '') {
        return;
      }

      setSelectRoom(null);
      navigate('/' + RoutePaths.directMessage.path + `?id=${_query}`, {
        replace: true,
      });
    },
    [navigate]
  );

  // ドロップダウンイベントの関数
  const handleChange = (value: string) => {
    // ドロップダウンの選択肢を変更
    setSelectFilter(value);

    // ページングの値をリセット
    resetPagingStatus();
  }

  // フィルターの有無によって表示するデータを変更
  const returnRoomDatas = () => {
    if (data !== undefined) {
      // フィルターが選択されている場合
      if (selectFilter === FILTER_IMPORTANT_STR) {
        return data?.rooms.filter(data => data.isImportant === true) ?? [];
      }
      if (selectFilter === FILTER_NOT_IMPORTANT_STR) {
        return data?.rooms.filter(data => data.isImportant === false) ?? [];
      }
    }
    return data?.rooms ?? [];
  }

  // ページの最終ドキュメントを管理する
  const handlePageChange = (direction: 'prev' | 'next') => {
    // ドキュメント情報をリセット
    firstDoc.current = undefined;
    lastDoc.current = undefined;

    // 選択したページが現在のページより大きい場合、次のページを取得する
    if (direction === 'next') {
      // 次のページを取得するには、最後のドキュメントを取得する
      lastDoc.current = data?.lastDoc;
      setCurrentPage(currentPage + 1);
    }
    // 選択したページが現在のページより小さい場合、前のページを取得する
    if (direction === 'prev') {
      // 前のページを取得するには、最初のドキュメントを取得する
      // 1ページ目の場合は、最初のドキュメントは存在しないため、undefinedを設定
      firstDoc.current = currentPage - 1 === 1 ? undefined : data?.firstDoc;
      setCurrentPage(currentPage - 1);
    }
  }

  return (
    <div>
      <SearchBoxWithDropdownBox placeholder="顧客IDを入力してください" onSearch={onSearch} handleChange={handleChange} options={options} defaultValue={options[0].label}/>
      <div style={{ height: '10px' }}></div>
      <Flex justify="space-between" style={{ maxHeight: '75vh' }}>
        <DMList
          totalCount={data?.totalCount ?? 0}
          data={returnRoomDatas()}
          pageSize={PAGE_SIZE}
          selectingRoom={selectRoom}
          searchedRoom={searchId !== null}
          onSelect={onClick}
          onClear={() => {
            setSelectRoom(null);
            navigate('/' + RoutePaths.directMessage.path, {
              replace: true,
            });
          }}
          pageNum={currentPage}
          handlePageChange={handlePageChange}
        />
        <Chat
          selectRoom={{
            mCustomer: selectRoomMCus?.mCustomer ?? null,
            mRoom: selectRoom,
          }}
        />
        <Flex
          style={{
            width: '32%',
            background: 'white',
            borderRadius: 3,
            overflow: 'auto',
            padding: '2%',
          }}
        >
          <MCustomerDetail selectCus={selectRoomMCus?.mCustomer ?? null} />
        </Flex>
      </Flex>
    </div>
  );
};
