import React, { useCallback, useRef, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Card,
  Space,
  DatePicker,
  Upload,
  Select,
  Image,
  UploadProps,
  message,
  Flex,
  Radio,
  InputNumber,
  Statistic,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { BroadCastFormType } from 'src/models/broadCast';
import { useLoginAccount } from 'src/globalStates/loginAccount';
import TextArea from 'antd/es/input/TextArea';
import { csvToArray } from 'src/util/array';
import { useBroadCastForm } from 'src/usecases/broadCast/form';
import { download } from 'src/util/download';
import { DownloadButton } from 'src/components/ui';
import { RcFile } from 'antd/es/upload';
import dayjs, { Dayjs } from 'dayjs';
import { useTransHistoryRepository } from 'src/repositories/transHistory/repository';
import { FullscreenLoading } from 'src/components/function/loading';
import { useMCustomerRepository } from 'src/repositories/mCustomer/repository';
import { toMCustomerId } from 'src/models/mCustomer';
import { MCustomerGetItemQuery } from 'src/usecases/mCustomer/reader';
import { TransHistoryGetFilteredListQuery } from 'src/usecases/transHistory/reader';

interface Prop {
  init?: BroadCastFormType;
}

// LINE ID検索用のクラス
class SearchLineIdsProps {
  public visitCount: number;
  public comparisionOptions: string;
  public dateRange: [Date | null, Date | null] | null;
  public searchCustomerInfo: { [lineId: string]: {[storeKey: string]: { visitNum: number }}} | undefined;

  // 固定値の定義
  public static readonly DEFAULT_COLON_STR = ':';

  // コンストラクタ
  constructor() {
    this.visitCount = 1;
    this.comparisionOptions = '';
    this.dateRange = null;
    this.searchCustomerInfo = {};
  }

  // LineIdのキーの数を取得する
  public get searchLineIdsCount(): number {
    return this.searchCustomerInfo ? Object.keys(this.searchCustomerInfo).length : 0;
  }

  // LineId一覧を取得する
  public get searchLineIdsKeys(): string[] {
    return this.searchCustomerInfo ? Object.keys(this.searchCustomerInfo) : [];
  }

  // (csv/Excel)出力用に１列目にLINE_ID、２列目に来店回数(店舗名)を設定する
  public get getStoreVisitInfo(): string[][] {
    let lineIdsData: string[][] = [];

    // 各LINE IDの来店情報を見る
    for (let lineId in this.searchCustomerInfo) {
      // 各店舗の来店回数を取得する
      // 来店店舗が複数ある場合は'A店(1), B店(2)'のように表示する
      let visitInfoStr = '';
      for (let storeKey in this.searchCustomerInfo[lineId]) {
        // 初回ではない場合はカンマを追加する
        if (visitInfoStr !== '') {
          visitInfoStr += '/';
        }
        visitInfoStr += `${storeKey.split(SearchLineIdsProps.DEFAULT_COLON_STR)[1]}(${this.searchCustomerInfo[lineId][storeKey].visitNum})`;
      }
      // LINE IDと来店情報を追加する
      lineIdsData.push([lineId, visitInfoStr]);
    }
    return lineIdsData;
  }

  // 検索条件をクリアする
  public clear() {
    this.visitCount = 1;
    this.comparisionOptions = '';
    this.dateRange = null;
    this.searchCustomerInfo = {};
  }
}

export const BroadCastForm: React.FC<Prop> = ({ init }) => {
  const loginAccount = useLoginAccount();

  const _formState = useBroadCastForm();

  const [form] = Form.useForm<BroadCastFormType>();

  const type = Form.useWatch('type', form);

  const lineIds = Form.useWatch('sendMCustomerLineIds', form);

  const [inputImage, setInputImage] = React.useState<RcFile | undefined>();

  // 以上、以下の定数
  const ABOVE_STR = 'above';
  const BELOW_STR = 'below';
  const ABOVE_JP_STR = '以上';
  const BELOW_JP_STR = '以下';
  // ハイフン
  const HYPHEN_STR = 'ー';
  // 期間設定用
  const { RangePicker } = DatePicker;
  // RangePicker用に型エイリアスを定義
  type NullableDayjs = Dayjs | null;
  // トランザクションレポジトリ
  const transHistorisRepository = useTransHistoryRepository();
  // mCustomerレポジトリ
  const mCustomerRepository = useMCustomerRepository();
  // 検索機能用の対象店舗取得
  const selectedStores = useRef<string[]>(init?.storeIds ?? []);
  // 検索処理ローディング
  const [searchLoading, setSearchLoading] = useState(false);
  // ラジオボタンの値
  const [radioValue, setRadioValue] = useState((init?.broadCast?.sendMCustomerLineIds?.length ?? 0) === 0 ? true : false);    
  // 以上、以下の選択肢
  const comparisionOptions = [{ value: ABOVE_STR, label: ABOVE_JP_STR },
  { value: BELOW_STR, label: BELOW_JP_STR }];
  // 検索条件[人数入力値]
  const [inputNumberValue, setInputNumberValue] = useState<number>(1);
  // 以上、以下の選択値
  const [selectedComparison, setSelectedComparison] = useState<string>(ABOVE_STR);
  // 検索条件[期間指定]
  const [dateRange, setDateRange] = useState<[NullableDayjs, NullableDayjs] | null>(null);
  // 配信対象者数
  const [matchingCustomersCnt, setMatchingCustomersCnt] = useState(HYPHEN_STR);  
  // 検索情報
  const searchInfo = useRef(new SearchLineIdsProps());

  const _needText =
    type === 'text' || type === 'textAndImage' || type === 'textAndTemplate';
  const _needImage = type === 'image' || type === 'textAndImage';
  const _needTemplate = type === 'template' || type === 'textAndTemplate';

  const reader = new FileReader();

  const props: UploadProps = {
    accept: 'text/csv',
    action: '/upload.do',
    maxCount: 1,
    showUploadList: lineIds !== null,
    beforeUpload: (file) => {
      const csvFile = file.type === 'text/csv';
      if (!csvFile) {
        message.error(`csvファイルのみアップロード可能です`);
        return Upload.LIST_IGNORE;
      }

      try {
        reader.readAsText(file, 'UTF-8');
        reader.onload = (e) => {
          if (!!!e?.target?.result || typeof e?.target?.result !== 'string') {
            message.error(`フォーマットが無効です`);
            return false;
          }

          const _csv = csvToArray(e.target.result.toString());
          if (_csv.length < 2 || (!_csv[0].includes('LINE_ID') && (_csv[0].length > 1 && !_csv[0][0].includes('LINE_ID')))) {
            message.error(`フォーマットが無効です`);
            return false;
          }

          let _lineIds: string[] = [];

          if (_csv[0].includes('LINE_ID')) {
            // サンプルフォーマットの場合
            const findIndex = _csv[0].indexOf('LINE_ID');
            _lineIds = _csv
            .filter(
              (_v, _i) =>
                _i !== 0 && _v[findIndex] !== '' && _v[findIndex] !== undefined
            )
            .map((_v) => _v[findIndex])
            .flat();
          }
          else {
            // 検索データフォーマットの場合
            const findIndex = _csv[0][0].indexOf('LINE_ID');
            _lineIds = _csv.
            filter(
              (_v, _i) => 
                _i !== 0 && _v[0][0] !== '' && _v[0][0] !== undefined
            )
            .map((_v) => _v[findIndex])
            .flat();
          }

          if (_lineIds.length > 500) {
            message.error(`送信するユーザを指定する場合は最大500名です`);
            return false;
          }

          form.setFieldValue('sendMCustomerLineIds', _lineIds);

          return false;
        };
        return false;
      } catch (error) {
        message.error(`エラーが発生しました`);
        return Upload.LIST_IGNORE;
      }
    },
    onRemove: () =>
      form.setFieldValue(
        'sendMCustomerLineIds',
        init?.broadCast?.sendMCustomerLineIds
      ),
  };

  const imageProps: UploadProps = {
    accept: 'image/*',
    action: '/upload.do',
    maxCount: 1,
    showUploadList: inputImage !== undefined,
    beforeUpload: (file) => {
      const isImage =
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg';
      if (!isImage) {
        message.error(`png,jpeg,jpg形式のみアップロード可能です`);
        return Upload.LIST_IGNORE;
      }

      setInputImage(file);

      return false;
    },
    onRemove: () => setInputImage(undefined),
  };

  const [loading, setLoading] = React.useState(false);
  const onSubmit = async (status: 'draft' | 'reserved') => {
    setLoading(true);
    const _value = form.getFieldsValue();

    await _formState.submit(
      {
        ..._value,
        broadCast: init?.broadCast,
      },
      status,
      inputImage
    );
    setLoading(false);
  };

  const onClickSendUserIds = useCallback(
    async (format: 'csv' | 'xlsx') => {
      if (!init?.broadCast) return;
      const _title = init.broadCast.title;
      download(
        [
          ['LINE_ID'],
          ...(init.broadCast.sendMCustomerLineIds ?? []).map((_id) => [_id]),
        ],
        `${_title ?? ''}${_title ? '_' : ''}ユーザリスト`,
        format
      );
    },
    [init?.broadCast]
  );

  const onClickSampleData = useCallback(async (format: 'csv' | 'xlsx') => {
    download(
      [
        ['LINE_ID'],
        ['U716f5f7517de3aadglfg2c7d83055910'],
        ['Uff64d02382d4asd7c825c5e7c30c20e0'],
        ['U50d2c782b8fbsdsffg6966caf4aca8e5'],
      ],
      'サンプルデータ',
      format
    );
  }, []);

  // 検索したLINE IDデータをダウンロードする
  // 検索条件(useStateで管理)によって出力内容が変わるのでuseCallbackは不使用
  const onClickSearchData = async (format: 'csv' | 'xlsx') => {
    // 更新がない場合
    if (matchingCustomersCnt === HYPHEN_STR) {
      message.error('更新を行なってください。');
      return;
    }

    // LINE IDが存在しない場合
    if (matchingCustomersCnt === '0') {
      message.error('配信対象者が存在しません。');
      return;
    } 

    // 検索条件文字列を作成
    let comparison = '';
    if (searchInfo.current.comparisionOptions === ABOVE_STR) {
      comparison = ABOVE_JP_STR;
    }
    else if (searchInfo.current.comparisionOptions === BELOW_STR) {
      comparison = BELOW_JP_STR;
    }
    
    // 全体の検索条件文字列を作成
    let searchCondition = '';
    // 来店回数の条件を作成
    let searchvisitInfoStr = `来店回数：${searchInfo.current.visitCount}回${comparison}`;
    if (searchInfo.current.dateRange && searchInfo.current.dateRange[0] && 
        searchInfo.current.dateRange[1]) {
      searchCondition += ` { 来店期間：${searchInfo.current.dateRange?.[0]?.toLocaleDateString()}～${searchInfo.current.dateRange?.[1]?.toLocaleDateString()}`
        + `、${searchvisitInfoStr} }`;
    }
    else {
      searchCondition += ` { ${searchvisitInfoStr} }`;
    }
    
    download(
      [
        ['LINE_ID' + searchCondition, '店舗名(来店回数)'],
        ...searchInfo.current.getStoreVisitInfo,
      ],
      '検索データ',
      format
    );
  };

  // 対象アカウントフォームで選択した店舗を取得する
  const onSelectStoreChange = (value: string[]) => {
    selectedStores.current = value;
    setMatchingCustomersCnt(HYPHEN_STR);
  }  

  // 送信対象のラジオボタンを変更する
  const onRadioChange = (e: any) => {
    setRadioValue(e.target.value);
    if (e.target.value) {
      // 全員の場合
      setMatchingCustomersCnt(HYPHEN_STR);
      // 検索条件をリセットする
      setDateRange(null);
      setInputNumberValue(1);
      setSelectedComparison(ABOVE_STR);
      searchInfo.current.clear();
    }
  }

  // 人数を入力する
  const onInputNumberChange = (value: number | null) => {
    if (typeof value === 'number') {
      setInputNumberValue(value);
    }
    setMatchingCustomersCnt(HYPHEN_STR);
  };

  // 以上、以下を選択する
  const onSelectChangeComparison = (value: string) => {
    setSelectedComparison(value);
    setMatchingCustomersCnt(HYPHEN_STR);
  }

  // 期間の範囲を指定する
  const onDateRangeChange = (dates: [NullableDayjs, NullableDayjs] | null) => {
    setDateRange(dates);
    setMatchingCustomersCnt(HYPHEN_STR);
  };

  // 先月の範囲を取得する
  const getPresetDateRange = (preset: string): [Dayjs, Dayjs] => {
    switch (preset) {
      // 今週 (例)2024/10/30日の場合 → 2024/10/28 ～ 2024/10/30
      case 'thisWeek':
        return [
          dayjs().startOf('week'),
          dayjs()
        ];
      // 先週 (例)2024/10/30日の場合 → 2024/10/21 ～ 2024/10/27
      case 'lastWeek':
        return [
          dayjs().subtract(1, 'week').startOf('week'),
          dayjs().subtract(1, 'week').endOf('week')
        ];
      // 今月 (例)2024/10/30日の場合 → 2024/10/01 ～ 2024/10/30
      case 'thisMonth':
        return [
          dayjs().startOf('month'),
          dayjs()
        ];
      // 先月 (例)2024/10/30日の場合 → 2024/09/01 ～ 2024/09/30
      case 'lastMonth':
        return [
          dayjs().subtract(1, 'month').startOf('month'),
          dayjs().subtract(1, 'month').endOf('month')
        ];
      // 半年前までの範囲 (例)2024/10/30日の場合 → 2024/05/01 ～ 2024/10/30
      case 'halfYear':
        return [
          dayjs().subtract(6, 'month').startOf('month'),
          dayjs()
        ];
      // 今年の範囲 (例)2024/10/30日の場合 → 2024/01/01 ～ 2024/10/30
      case 'thisYear':
        return [
          dayjs().startOf('year'),
          dayjs()
        ];
      // 去年の範囲 (例)2024/10/30日の場合 → 2023/01/01 ～ 2023/12/31
      case 'lastYear':
        return [
          dayjs().subtract(1, 'year').startOf('year'),
          dayjs().subtract(1, 'year').endOf('year')
        ];
      default:
        return [dayjs(), dayjs()];
    }
  };

  // 検索機能
  const applySearch = async () => {
    // ローディングを表示する
    setSearchLoading(true);

    // 各トランザクションデータからmCustomerIdと回数を保持する辞書型データ構造を作成
    // storeKeyは[店舗ID：店舗名]を使用する
    const customerVisitCounts: {[mCusId: string]: {[storeKey: string]: { visitNum: number }}} = {};
    // 条件に合うユーザの数をカウントする
    let matchingCustomers = 0;

    if (selectedStores.current.length !== 0) {
      // 時間を取得する
      const fromDt = dateRange && dateRange[0] ? new Date(dateRange[0].toDate().setHours(0, 0, 0, 0)) : null;
      const toDt = dateRange && dateRange[1] ? new Date(dateRange[1].toDate().setHours(23, 59, 59, 999)) : null;

      // 店舗のトランザクションを取得する
      const query: TransHistoryGetFilteredListQuery = {
        fromDate: fromDt,
        toDate: toDt,
        selectedStoreIds: selectedStores.current,
      };
      const transHisDatas = await transHistorisRepository.getAllDocsFilterStoreId(query);

      // mCustomerIdを重複せずに取得する
      const mCustomerIds: MCustomerGetItemQuery[] = [];
      transHisDatas.forEach((_transHisData) => {
        // storeKeyを作成する
        const storeKey = _transHisData.storeId + SearchLineIdsProps.DEFAULT_COLON_STR + _transHisData.storeName;
        // mCustomerIdが存在し、重複していない場合
        if (_transHisData.mCustomerId && customerVisitCounts[_transHisData.mCustomerId] === undefined) {
          // クエリ変数を作成して追加
          mCustomerIds.push({ mCustomerId: toMCustomerId(_transHisData.mCustomerId) });
          // 重複確認用の配列に追加
          customerVisitCounts[_transHisData.mCustomerId] = { [storeKey]: { visitNum: 1 } };
        }
        else {
          // 重複している場合
          if (_transHisData.mCustomerId) {
            // 店舗名が存在しない場合(店舗IDではなく店舗名ベースで数えている)
            if (customerVisitCounts[_transHisData.mCustomerId][storeKey] === undefined) {
              // 店舗名を追加
              customerVisitCounts[_transHisData.mCustomerId][storeKey] = { visitNum: 1 };
            }
            else {
              // 回数を追加
              customerVisitCounts[_transHisData.mCustomerId][storeKey].visitNum += 1;
            }
          }
        }
      });

      // 回数でフィルタリングする
      for (let [mCusId, value] of Object.entries(customerVisitCounts)) {
        for (let [storeKey, visitNum] of Object.entries(value)) {
          if (selectedComparison === ABOVE_STR && visitNum.visitNum < inputNumberValue) {
            // 条件に合わない場合は削除する(回数未満の場合 以上選択、来店回数が少ない場合)
            delete customerVisitCounts[mCusId][storeKey];
          }
          else if (selectedComparison === BELOW_STR && visitNum.visitNum > inputNumberValue) {
            // 条件に合わない場合は削除する(回数超過の場合 以下選択、来店回数が多い場合)
            delete customerVisitCounts[mCusId][storeKey];
          }

          // lineIdのStoreKeyが0の場合、lineIdを削除する
          if (Object.keys(value).length === 0) {
            delete customerVisitCounts[mCusId];
          }
        }
      }

      // mCustomerIdを集計する
      const mCustomersDatas = await mCustomerRepository.getDocs(mCustomerIds);
      const checkMCustomerIds = mCustomersDatas.map((_mCustomer) => _mCustomer.id);

      // LINE ID を取得する
      searchInfo.current.clear();
      let mCusIdsInfo: { [mCusId: string]: string } = {};

      // mCustomerに存在するLINE IDを取得する
      for (let [mCusId, value] of Object.entries(customerVisitCounts)) {
        // firebaseのmCustomerにあるLINE IDの場合
        if (checkMCustomerIds.includes(toMCustomerId(mCusId))) {
          // mCustomerId: LINE IDを追加する
          const lineId = mCustomersDatas.find((_mCustomer) => _mCustomer.id === toMCustomerId(mCusId))?.lineId;
          if (lineId) {
            mCusIdsInfo[mCusId] = lineId;
          }
        }
      }

      // LINE IDを紐付けて取得する
      Object.entries(mCusIdsInfo).forEach(([mCusId, lineId]) => {
        // LINE IDが存在しない場合
        if (customerVisitCounts[mCusId] !== undefined && searchInfo.current.searchCustomerInfo) {
          // LINE IDを追加する
          searchInfo.current.searchCustomerInfo[lineId] = customerVisitCounts[mCusId];
        }
      });
      // 人数を取得する
      matchingCustomers = Object.entries(mCusIdsInfo).length;

      // searchInfoに設定する
      searchInfo.current.visitCount = inputNumberValue;
      searchInfo.current.comparisionOptions = selectedComparison;
      searchInfo.current.dateRange = [fromDt, toDt];
    }
    // 人数を設定する
    setMatchingCustomersCnt(matchingCustomers.toString());
    // ローディングを非表示にする
    setSearchLoading(false);
    // メッセージを表示する
    message.success('配信対象者を検索しました。');
  }

  return (
    <FullscreenLoading isLoading={searchLoading}>
      <Card style={{ paddingTop: '3%' }}>
        <Form
          labelWrap
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16, offset: 1 }}
          layout="horizontal"
          style={{ maxWidth: 900, margin: 'auto' }}
          form={form}
          initialValues={init}
          disabled={loading}
        >
          <Form.Item<BroadCastFormType>
            label="タイトル"
            name="title"
            rules={[{ required: true, message: 'タイトルを入力してください' }]}
            tooltip="こちらはお客さまに公開されません"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="storeIds"
            label="送信するアカウント"
            rules={[
              {
                message: '送信する公式アカウントを選択してください',
                type: 'array',
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="送信する公式アカウントを選択してください"
              onChange={onSelectStoreChange}
            >
              {loginAccount.stores?.map((_store) => {
                return (
                  <Select.Option value={_store.storeId} key={_store.storeId}>
                    {_store.storeName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="sendAt"
            label="送信日時"
            getValueProps={(i) => ({
              value: !!!i ? undefined : dayjs(i),
            })}
          >
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm"
              disabledDate={(d) => d.diff(dayjs(), 'seconds') < 0}
            />
          </Form.Item>
          <Form.Item
            name="type"
            label="送信内容タイプ"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={[
                { value: 'text', label: 'テキストのみ' },
                { value: 'image', label: '画像のみ' },
                {
                  value: 'template',
                  label: 'アクション付き画像のみ（クーポンなど）',
                },
                { value: 'textAndImage', label: 'テキスト＆画像' },
                {
                  value: 'textAndTemplate',
                  label: 'テキスト＆アクション付き画像',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            hidden={!_needText}
            name="text"
            label="テキスト"
            required={_needText}
          >
            <TextArea minLength={1} maxLength={300} />
          </Form.Item>
          <Form.Item
            name="image"
            hidden={!(_needImage || _needTemplate)}
            label="画像"
            required={_needImage || _needTemplate}
          >
            <Space style={{ maxWidth: '100%', overflow: 'auto' }}>
              <Upload.Dragger {...imageProps} style={{ maxWidth: '400px' }}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  クリックまたはファイルをドラッグ
                </p>
                <p className="ant-upload-hint">
                  png,jpeg,jpg形式に対応しています
                </p>
              </Upload.Dragger>
              <Image
                src={
                  _needImage
                    ? init?.broadCast?.message.imageUrl ?? undefined
                    : init?.broadCast?.message.tempImageUrl ?? undefined
                }
                width="200px"
              />
            </Space>
          </Form.Item>
          <Form.Item
            hidden={!_needTemplate}
            name="tempLabel"
            label="画像上のテキスト"
            tooltip={<Image width={200} src="/assets/images/tempLabel.jpg" />}
            required={_needTemplate}
          >
            <Input
              type="text"
              placeholder="クーポンをみる"
              minLength={1}
              maxLength={12}
            />
          </Form.Item>
          <Form.Item
            hidden={!_needTemplate}
            name="altText"
            label="通知に表示されるテキスト"
            required={_needTemplate}
          >
            <Input type="text" maxLength={400} />
          </Form.Item>
          <Form.Item
            hidden={!_needTemplate}
            name="tempUri"
            label="リンク先"
            required={_needTemplate}
          >
            <Input type="url" placeholder="http://mysite.com" maxLength={1000} />
          </Form.Item>
          <Form.Item
            name="sendAll"
            label="送信対象"
            required
            tooltip="送信者を限定する場合のみアップロードが必要です。アップロードがない場合は友達全員に送信されます。"
          >
            <Radio.Group name="sendTarget" onChange={onRadioChange}>
              <Radio value={true}>全員</Radio>
              <Radio value={false}>ファイルアップロード</Radio>
            </Radio.Group>
          </Form.Item>
          {radioValue === false ?
            <div>
              <Form.Item
                label="検索条件1 来店期間"
              >
                <Flex>
                  <RangePicker
                    onChange={onDateRangeChange}
                    placeholder={['開始日', '終了日']}
                    presets={[
                      {
                        label: '今週',
                        value: getPresetDateRange('thisWeek'),
                      },
                      {
                        label: '先週',
                        value: getPresetDateRange('lastWeek'),
                      },
                      {
                        label: '今月',
                        value: getPresetDateRange('thisMonth'),
                      },
                      {
                        label: '先月',
                        value: getPresetDateRange('lastMonth'),
                      },
                      {
                        label: '半年前まで',
                        value: getPresetDateRange('halfYear'),
                      },
                      {
                        label: '今年中',
                        value: getPresetDateRange('thisYear'),
                      },
                      {
                        label: '去年中',
                        value: getPresetDateRange('lastYear'),
                      },
                    ]}
                  />
                </Flex>
              </Form.Item>
              <Form.Item
                label="検索条件2 来店情報"
              >
                <Flex>
                  <InputNumber addonAfter="回" defaultValue={1} min={1} max={100} onChange={onInputNumberChange}
                    style={{ maxWidth: '100px' }} />
                  <Select
                    options={comparisionOptions}
                    onChange={onSelectChangeComparison}
                    defaultValue={comparisionOptions[0].value}
                    style={{ maxWidth: '80px', marginLeft: '10px' }}></Select>
                </Flex>
              </Form.Item>
              <Form.Item
                label="配信対象者数"
              >
                <Flex>
                  <Statistic value={matchingCustomersCnt}
                    style={{ display: 'flex', alignItems: 'center', fontSize: '20px', marginRight: '15px', maxHeight: '32px' }} />
                  <Button style={{ marginRight: '15px' }} onClick={() => applySearch()}>更新</Button>
                  <div>
                    <DownloadButton
                      name='検索データをダウンロード'
                      tooltip="検索したユーザリストをダウンロードできます"
                      onClick={onClickSearchData}
                    />
                  </div>
                </Flex>
              </Form.Item>
              <Form.Item
                name="sendMCustomerLineIds"
                label={
                  <Flex vertical gap={10} align="end">
                    <DownloadButton
                      name="サンプルデータ"
                      onClick={onClickSampleData}
                      size="small"
                      type="link"
                    />
                  </Flex>
                }
              >
                <Flex
                  gap={10}
                  style={{ maxWidth: '100%', overflow: 'auto', alignItems: 'end' }}
                >
                  <Upload.Dragger {...props} style={{ maxWidth: '400px' }}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      クリックまたはファイルをドラッグ
                    </p>
                    <p
                      className="ant-upload-hint"
                      style={{ whiteSpace: 'pre-wrap', textAlign: 'start' }}
                    >{`※送信したい顧客のLINEのIDをアップロードする必要があります\n※データの１行目に「LINE_ID」が含まれている必要があります\n※最大500ユーザとなります\n※csv形式に対応しています`}</p>
                  </Upload.Dragger>
                  <div
                    hidden={
                      (init?.broadCast?.sendMCustomerLineIds?.length ?? 0) === 0
                    }
                  >
                    <DownloadButton
                      tooltip="アップロードしたユーザリストをダウンロードできます"
                      onClick={onClickSendUserIds}
                    />
                  </div>
                </Flex>
              </Form.Item>
            </div>
          : null}
          <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
            <Space>
              <Button
                disabled={init?.broadCast?.status === 'sent'}
                loading={loading}
                type="dashed"
                htmlType="submit"
                onClick={() => onSubmit('draft')}
              >
                下書き保存
              </Button>
              <Button
                disabled={init?.broadCast?.status === 'sent'
                }
                loading={loading}
                type="primary"
                htmlType="button"
                onClick={() => onSubmit('reserved')}
              >
                確定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </FullscreenLoading>
  );
};
