import React from 'react';
import {
  Firestore,
  getDocs,
  query,
  collection,
  QueryConstraint,
  where,
  limit,
  orderBy,
  startAfter,
  DocumentSnapshot,
} from 'firebase/firestore/lite';
import { useFirestoreClient } from 'src/firebase';
import { Collection } from 'src/models/common';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';
import { TransHistoryRepository } from './interface';
import { toTransHistoryFromDoc } from './converter';
import { getFieldName, TransHistory } from 'src/models/transHistory';
import { TransHistoryGetFilteredListQuery, TransHistoryGetListQuery } from 'src/usecases/transHistory/reader';

export const useTransHistoryRepository = (): TransHistoryRepository => {
  const db = useFirestoreClient();
  const account = useLoginAccount();
  return React.useMemo(
    () => createTransHistoryRepository(db, account),
    [db, account]
  );
};

const createTransHistoryRepository = (
  db: Firestore,
  account: LoginAccountState
) => {
  return {
    async getAllDocs(appQuery: TransHistoryGetListQuery) {
      if (account.merchantId === undefined || account.storeIds === undefined) {
        throw new Error('エラーが発生しました');
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId?.toString()}/${
          Collection.TransHistories
        }`
      );

      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        where(getFieldName('mCustomerId'), '==', appQuery.mCustomerId),
        orderBy(getFieldName('transCreatedAt'), 'desc'),
      ];

      if (!account.master) {
        queries.push(where(getFieldName('storeId'), 'in', account.storeIds));
      }

      return (
        await getDocs(query(collRef, ...queries, limit(appQuery.limit)))
      ).docs.map((_doc) => toTransHistoryFromDoc(_doc.data()!, _doc.id));
    },

    // 店舗IDで絞り込んだトランザクションを取得
    async getAllDocsFilterStoreId(appQuery: TransHistoryGetFilteredListQuery) {
      // 最大トランザクション取得件数
      const MAX_COLLECTION_COUNT = 500000;
      // 1回の取得件数(firebaseの設定ルールに合わせて値を設定)
      const COLLECTION_LIMIT_COUNT = 200;
      // IN句の制限数
      const IN_LIMIT_COUNT = 30;

      if (account.merchantId === undefined) {
        throw new Error('エラーが発生しました');
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId?.toString()}/${
          Collection.TransHistories
        }`
      );

      // クエリを作成
      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
      ];

      // 開始日時を取得条件に追加
      if (appQuery.fromDate !== null) {
        queries.push(where(getFieldName('createdAt'), '>=', appQuery.fromDate));
      }

      // 終了日時を取得条件に追加
      if (appQuery.toDate !== null) {
        queries.push(where(getFieldName('createdAt'), '<=', appQuery.toDate));
      }

      // createdAtでのソートを追加
      queries.push(orderBy(getFieldName('createdAt'), 'desc'));

      const allTransactions: TransHistory[] = [];
      let lastDoc: DocumentSnapshot | null = null;
      let hasMore = true;
      let totalFetched = 0;

      // クエリのin句の制限が30件なので、30件ごとに取得する
      for (let storeIdsCnt = 0; storeIdsCnt < appQuery.selectedStoreIds.length; storeIdsCnt += IN_LIMIT_COUNT) {
        // 変数を初期化
        lastDoc = null;
        hasMore = true;

        // 各件ごとに取得する
        const chunkStoreIds = appQuery.selectedStoreIds.slice(storeIdsCnt, storeIdsCnt + IN_LIMIT_COUNT);
        // 作成済のクエリ部分を格納
        const pushSelectStoreQuery = [...queries];
        // 選択された店舗IDを取得条件に追加
        pushSelectStoreQuery.push(where(getFieldName('storeId'), 'in', chunkStoreIds));

        // 1回の取得件数が最大取得件数を超える場合は、複数回取得する
        while (hasMore && totalFetched < MAX_COLLECTION_COUNT) {
          const pazingQueries = [...pushSelectStoreQuery];

          // startAfter関数で取得開始位置を指定(ページング処理)
          if (lastDoc) {
            pazingQueries.push(startAfter(lastDoc));
          }

          // トランザクションを集計
          const querySnapshot = await getDocs(query(collRef, ...pazingQueries, limit(COLLECTION_LIMIT_COUNT)));
          // 取得したトランザクションをマッピング
          const docs = querySnapshot.docs.map((_doc) => toTransHistoryFromDoc(_doc.data()!, _doc.id));
          // 取得したトランザクションを全体に追加
          allTransactions.push(...docs);
          // 次回取得するかを判定する変数を更新
          lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
          totalFetched += docs.length;
          hasMore = docs.length > 0;
        }
      }

      return allTransactions;
    }
  };
};
