import { message } from 'antd';
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { BroadCast, BroadCastFormType } from 'src/models/broadCast/type';
import { BroadCastRepository } from 'src/repositories/braodCast/interface';
import { useBroadCastRepository } from 'src/repositories/braodCast/repository';
import { StorageRepository } from 'src/repositories/storage/interface';
import { useStorageRepository } from 'src/repositories/storage/repository';
import { useBroadCastCacheMutator } from './cache';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';

export const useBroadCastForm = () => {
  const repository = useBroadCastRepository();
  const storageRepo = useStorageRepository();
  const navigator = useNavigate();
  const account = useLoginAccount();
  const mutator = useBroadCastCacheMutator();

  return React.useMemo(
    () =>
      createBroadCastFormConstructor({
        repository,
        storageRepo,
        navigator,
        account,
        mutator,
      }),
    [repository, storageRepo, navigator, account, mutator]
  );
};

const createBroadCastFormConstructor = ({
  repository,
  storageRepo,
  navigator,
  account,
  mutator,
}: {
  repository: BroadCastRepository;
  storageRepo: StorageRepository;
  navigator: NavigateFunction;
  account: LoginAccountState;
  mutator: ReturnType<typeof useBroadCastCacheMutator>;
}) => ({
  async submit(
    value: BroadCastFormType,
    status: 'draft' | 'reserved',
    image?: File
  ) {
    try {
      // タイトルが未入力の場合
      if (value.title === undefined || value.title === '') {
        message.error('タイトルを入力してください');
        return;
      }

      // 送信内容タイプが未選択の場合
      if (value.type === undefined) {
        message.error('送信内容タイプを選択し、内容を入力してください');
        return;
      }

      const _needImage =
        value.type === 'image' || value.type === 'textAndImage';

      const _needTempImage =
        value.type === 'template' || value.type === 'textAndTemplate';

      var _imageUrl = _needImage
        ? value.broadCast?.message?.imageUrl ?? null
        : _needTempImage
        ? value.broadCast?.message?.tempImageUrl ?? null
        : null;

      if (image) {
        _imageUrl = await storageRepo.upload({
          file: image!,
          fileName: image!.name,
          collPath: 'BroadCasts',
        });
      } else {
        if (_needImage) {
          if (!!!value.broadCast?.message?.imageUrl) {
            message.error('画像をアップロードしてください');
            return;
          }
          _imageUrl = value.broadCast!.message!.imageUrl;
        }
        if (_needTempImage) {
          if (!!!value.broadCast?.message?.tempImageUrl) {
            message.error('画像をアップロードしてください');
            return;
          }
          _imageUrl = value.broadCast!.message!.tempImageUrl;
        }
      }

      // 送信対象が未選択の場合
      if (value.sendAll === undefined) {
        message.error('送信対象を選択してください');
        return;
      }

      // 送信対象がファイルアップロードで、ファイルが未選択の場合
      if (value.sendAll === false && value.sendMCustomerLineIds === undefined) {
        message.error('ファイルがアップロードされていません');
        return;
      }

      const _message = {
        altText:
          value.type === 'template' || value.type === 'textAndTemplate'
            ? value.altText
            : null,
        imageUrl:
          value.type === 'image' || value.type === 'textAndImage'
            ? _imageUrl
            : null,
        tempImageUrl:
          value.type === 'template' || value.type === 'textAndTemplate'
            ? _imageUrl
            : null,
        tempLabel:
          value.type === 'template' || value.type === 'textAndTemplate'
            ? value.tempLabel
            : null,
        tempUri:
          value.type === 'template' || value.type === 'textAndTemplate'
            ? value.tempUri
            : null,
        text:
          value.type === 'text' ||
          value.type === 'textAndImage' ||
          value.type === 'textAndTemplate'
            ? value.text
            : null,
        type: value.type,
      };

      if (!!!value.broadCast) {
        const _broadCast: BroadCast = {
          createdAt: new Date(),
          deletedAt: null,
          documentStatus: 'active',
          message: _message,
          requestIds: [],
          results: [],
          sendAt: status === 'draft' ? null : value.sendAt?.toDate() ?? null,
          sendMCustomerLineIds: value.sendAll
            ? null
            : value.sendMCustomerLineIds ?? [],
          sentAt: null,
          status: status,
          storeIds: value.storeIds ?? [],
          title: value.title,
          updatedAt: new Date(),
        };

        await repository.createDoc(_broadCast, account);

        mutator.mutateList({ account });

        message.success('新規作成しました');
      } else {
        const _broadCast: BroadCast = {
          ...value.broadCast,
          message: _message,
          sendAt: status === 'draft' ? null : value.sendAt?.toDate() ?? null,
          sendMCustomerLineIds: value.sendAll
            ? null
            : value.sendMCustomerLineIds ?? [],
          status: status,
          storeIds: value.storeIds ?? [],
          title: value.title,
          updatedAt: new Date(),
        };

        await repository.update(_broadCast, account);

        mutator.mutateItem(
          { bId: value.broadCast?.id },
          { broadCast: value },
          true
        );

        message.success('更新しました');
      }

      navigator('../');
    } catch (error: any) {
      message.error(error?.toString() ?? 'エラーが発生しました');
      return;
    }
  },
});
