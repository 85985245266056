import React from 'react';
import { Table, TableColumnsType, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { columns } from './table.type';
import { BroadCast } from 'src/models/broadCast';
import { useBroadCastList } from 'src/usecases/broadCast/reader';
import { useLoginAccount } from 'src/globalStates/loginAccount';

export const BroadCastTable: React.FC = () => {
  const navigate = useNavigate();

  const { stores } = useLoginAccount();

  const onClickEdit = React.useCallback(
    (v: BroadCast) => {
      return {
        onClick: () => {
          if (!v?.id) return;
          navigate(v.id!);
          return;
        },
      };
    },
    [navigate]
  );

  const values = useBroadCastList();

  const expandedRowRender = (broadCast: BroadCast) => {
    if ((broadCast.sendMCustomerLineIds?.length ?? 0) > 0) {
      return (
        <Typography.Text>
          配信対象がファイルアップロードの場合は統計情報がありません
        </Typography.Text>
      );
    }

    const columns: TableColumnsType<{}> = [
      {
        title: '店舗',
        dataIndex: 'storeId',
        key: 'storeId',
        ellipsis: {
          showTitle: false,
        },
        render: (v: string) => {
          return stores?.find((store) => store.storeId === v)?.storeName ?? '';
        },
      },
      {
        title: '配信数',
        dataIndex: 'numOfDelivered',
        key: 'numOfDelivered',
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: '開封した人数',
        dataIndex: 'uniqueImpression',
        key: 'uniqueImpression',
        ellipsis: {
          showTitle: false,
        },
      },
    ];

    const data = [];
    for (let i = 0; i < broadCast.results.length; ++i) {
      data.push({
        key: i.toString(),
        storeId: broadCast.results[i].storeId ?? '',
        numOfDelivered: broadCast.results[i].numOfDelivered ?? '',
        uniqueImpression: broadCast.results[i].uniqueImpression ?? '',
      });
    }
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  return (
    <Table<BroadCast>
      onRow={onClickEdit}
      columns={columns}
      expandable={{
        expandedRowRender,
      }}
      dataSource={values.data?.broadCasts ?? []}
    />
  );
};
