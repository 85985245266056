import useSWR from 'swr';
import { transHistoryCacheKeyGenerator } from './cache';
import { TransHistory } from 'src/models/transHistory';
import { useTransHistoryRepository } from 'src/repositories/transHistory/repository';
import { MCustomerId } from 'src/models/mCustomer';
import { message } from 'antd';

export type TransHistoryGetListFormResponse = {
  transHistories: TransHistory[];
};

export type TransHistoryGetListQuery = {
  mCustomerId: MCustomerId;
  limit: number;
};

// 配信機能での絞込条件格納用
export type TransHistoryGetFilteredListQuery = {
  fromDate: Date | null;
  toDate: Date | null;
  selectedStoreIds: string[];
}

export const useTransHistoryItem = (query: TransHistoryGetListQuery) => {
  const repository = useTransHistoryRepository();

  return useSWR<TransHistoryGetListFormResponse>(
    transHistoryCacheKeyGenerator.generateItemKey(query),
    async () => {
      return {
        transHistories: await repository.getAllDocs(query),
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};
