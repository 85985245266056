import React from 'react';
import {
  getDocs,
  collection,
  Firestore,
  query,
  QueryConstraint,
  where,
  limit,
  orderBy,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  startAfter,
  endBefore,
  getCount,
  limitToLast
} from 'firebase/firestore/lite';
import { useFirestoreClient } from 'src/firebase';
import { getFieldName } from 'src/models/messageRoom';
import { Collection } from 'src/models/common';
import {
  useLoginAccount,
  LoginAccountState,
} from 'src/globalStates/loginAccount';
import { MessageRoomRepository } from './interface';
import { toMessageRoomFromDoc } from './converter';
import {
  MessageRoomGetItemQuery,
  MessageRoomListQuery,
} from 'src/usecases/messageRoom/reader';
import { validData } from 'src/util/validate';
import { toUpdateFsDoc } from '../common/firestore';
import { message } from 'antd';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { PAGE_SIZE } from 'src/components/page/directMessage/directMessage';

export const useMessageRoomRepository = (): MessageRoomRepository => {
  const db = useFirestoreClient();
  const account = useLoginAccount();

  return React.useMemo(
    () => createMessageRoomRepository(db, account),
    [db, account]
  );
};

const createMessageRoomRepository = (
  db: Firestore,
  account: LoginAccountState
) => {
  return {
    async getList({ firstDoc, lastDoc }: MessageRoomListQuery) {
      if (account.merchantId === undefined) {
        throw new Error('エラーが発生しました');
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.MessageRooms
        }`
      );

      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        orderBy(getFieldName('lastMessageAt'), 'desc'),
      ];

      if (!account.master) {
        queries.push(where(getFieldName('storeId'), 'in', account.storeIds));
      }

      // クエリのリミット設定、デフォルトはlimit使用
      let qLimit = limit(PAGE_SIZE);

      // ページング処理
      if (firstDoc !== undefined) {
        // 前のページ分のデータを取得
        queries.push(endBefore(firstDoc));
        qLimit = limitToLast(PAGE_SIZE);
      }
      else if (lastDoc !== undefined) {
        // 次のページ分のデータを取得
        queries.push(startAfter(lastDoc));
      }

      // メッセージルームの取得
      const snapshot = await getDocs(query(collRef, ...queries, qLimit));

      return { 
        messageRooms: snapshot.docs.map((doc) => toMessageRoomFromDoc(doc.data(), doc.id)),
        firstDoc: snapshot.docs[0] as QueryDocumentSnapshot,
        lastDoc: snapshot.docs[snapshot.docs.length - 1] as QueryDocumentSnapshot,
      };
    },

    async getDocFromMCusId({ mCustomerId }: MessageRoomListQuery) {
      if (account.merchantId === undefined || mCustomerId === undefined) {
        throw new Error('エラーが発生しました');
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MessageRooms
        }`
      );
      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        where(getFieldName('mCusId'), '==', mCustomerId),
      ];
      
      // メッセージルームの取得
      const snapshot = await getDocs(query(collRef, ...queries, limit(PAGE_SIZE)));

      return {
        messageRooms: snapshot.docs.map((doc) => toMessageRoomFromDoc(doc.data(), doc.id)),
        firstDoc: snapshot.docs[0].data() as QueryDocumentSnapshot,
        lastDoc: snapshot.docs[snapshot.docs.length - 1].data() as QueryDocumentSnapshot,
      }
    },

    async getDoc({ mRoomId }: MessageRoomGetItemQuery) {
      if (!!!account.merchantId || !!!mRoomId) {
        throw new Error('エラーが発生しました');
      }

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MessageRooms
        }/${mRoomId.toString()}`
      );

      const snapshot = await getDoc(docRef);

      if (!snapshot.exists) {
        return null;
      }

      const _mRoom = toMessageRoomFromDoc(snapshot.data()!, snapshot.id);
      if (!account.storeIds?.includes(_mRoom.storeId)) {
        return null;
      }

      if (!validData([_mRoom.storeId], account)) {
        throw new Error('権限がありません');
      }

      return _mRoom;
    },

    async update({ mRoomId }: MessageRoomGetItemQuery) {
      if (!!!account.merchantId || !!!mRoomId) {
        throw new Error('エラーが発生しました');
      }

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MessageRooms
        }/${mRoomId.toString()}`
      );

      // アップデートデータを前処理
      const docSnap = await getDoc(docRef);
      let updateData:any = toUpdateFsDoc({ unreadMessages: 0,});

      if (docSnap.exists()) {
        const getData = docSnap.data();
        if (getData.hasOwnProperty('isImportant')) {
          await updateDoc(docRef, updateData);
        }
        else {
          await updateDoc(docRef, { isImportant: false, ...updateData });
        }
      }
    },

    // 重要情報を設定するupdate関数
    async updateIsImportant(mRoomId: string , isImportant: boolean): Promise<boolean> {
      
      // オフライン状態を確認
      if (!navigator.onLine) {
        message.error('オフライン状態です。インターネット接続を確認してください。');
        return true;
      }

      if (!!!account.merchantId || !!!mRoomId) {
        throw new Error('エラーが発生しました。');
      }

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${Collection.MessageRooms
        }/${mRoomId}`
      );

      // 返り値設定
      let ret = true;
      try {
        const dt = serverTimestamp();
        await updateDoc(docRef, { isImportant: isImportant, updatedAt: dt });
        message.success('重要度を反映しました。');
      } catch (err) {
        ret = false;
        throw new Error('エラーが発生しました。');
      }
      finally {
        return ret;
      }
    },

    // 重要設定(true)がされているメッセージルームを取得する
    async getDocIsImportant( { firstDoc, lastDoc }: MessageRoomListQuery) {
      // マーチャントIDが取得できない場合
      if (account.merchantId === undefined) {
        throw new Error('エラーが発生しました');
      }

      // メッセージルームのコレクションを取得
      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MessageRooms
        }`
      );

      // クエリの設定
      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        where(getFieldName('isImportant'), '==', true),
        orderBy(getFieldName('lastMessageAt'), 'desc'),
      ];

      // リミット設定
      let qLimit = limit(PAGE_SIZE);
      // ページング処理
      if (firstDoc !== undefined) {
        // 前のページ分のデータを取得
        queries.push(endBefore(firstDoc));
        qLimit = limitToLast(PAGE_SIZE);
      }
      else if (lastDoc !== undefined) {
        // 次のページ分のデータを取得
        queries.push(startAfter(lastDoc));
      }

      // メッセージルームの取得
      const snapshot = await getDocs(query(collRef, ...queries, qLimit));

      return {
        messageRooms: snapshot.docs.map((doc) => toMessageRoomFromDoc(doc.data(), doc.id)),
        firstDoc: snapshot.docs[0] as QueryDocumentSnapshot,
        lastDoc: snapshot.docs[snapshot.docs.length - 1] as QueryDocumentSnapshot,
      };
    },

    // リストの総件数を取得する
    async getCount({ mCustomerId, isImportant }: MessageRoomListQuery) {
      // マーチャントIDが取得できない場合
      if (account.merchantId === undefined) {
        throw new Error('エラーが発生しました');
      }

      // メッセージルームのコレクションを取得
      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.MessageRooms
        }`
      );

      if (mCustomerId !== null && mCustomerId !== '') {
        // mCusId検索を行う場合
        const queries: Array<QueryConstraint> = [
          where(getFieldName('documentStatus'), '==', 'active'),
          where(getFieldName('mCusId'), '==', mCustomerId),
        ];

        const snapshot = await getCount(query(collRef, ...queries));
        return snapshot.data().count;
      }
      else if (isImportant !== undefined && isImportant === true) {
        // 重要度絞り込み設定している場合
        const queries: Array<QueryConstraint> = [
          where(getFieldName('documentStatus'), '==', 'active'),
          where(getFieldName('isImportant'), '==', isImportant),
          orderBy(getFieldName('lastMessageAt'), 'desc'),
        ];

        const snapshot = await getCount(query(collRef, ...queries));
        return snapshot.data().count;
      }
      else {
        // それ以外
        const queries: Array<QueryConstraint> = [
          where(getFieldName('documentStatus'), '==', 'active'),
          orderBy(getFieldName('lastMessageAt'), 'desc'),
        ];

        if (!account.master) {
          queries.push(where(getFieldName('storeId'), 'in', account.storeIds));
        }

        // メッセージルームの取得
        const snapshot = await getCount(query(collRef, ...queries));
        return snapshot.data().count;
      }
    }
  };
};
